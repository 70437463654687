export const tHeaderList: any = Object.freeze([
  {
    type: 'selection',
    prop: 'selectStatus',
    width: '50',
    label: ''
  },
  { label: '方向', prop: 'entrustBs', type: 'entrustBs', width: '' },
  { label: '客户账号', prop: 'fundAccount', type: '', width: '' },
  { label: '客户', prop: 'nameCn', type: '', width: '' },
  {
    label: '股票名称/代码',
    prop: 'enName',
    type: 'twoLinesNewsLines',
    width: '220px',
    propList: [
      { prop: 'stockName', icon: 'exchangeType', class: 'lines-stock-name' },
      { prop: 'stockCode', class: 'lines-stock-code' },
      { prop: 'exchangeType', class: 'lines-stock-type' }
    ]
  },
  { label: '委托价格', prop: 'entrustPrice', type: 'price', width: '' },
  {
    label: '委托数量',
    prop: 'entrustAmount',
    type: 'permillage',
    width: ''
  },
  { label: '成交价格', prop: 'businessPriceU', type: '', width: '' },
  {
    label: '成交数量',
    prop: 'businessAmount',
    type: 'permillage',
    width: ''
  },
  {
    label: '交易状态',
    prop: 'entrustStatus',
    type: 'status',
    width: ''
  },
  {
    label: '订单类型',
    prop: 'entrustProp',
    type: 'entrustProp',
    width: ''
  },
  {
    label: '订单号',
    prop: 'externalOrderNo',
    type: '',
    width: ''
  },
  {
    label: '下单时间',
    prop: 'createTime',
    type: 'date',
    width: '160'
  },
  {
    label: '操作',
    type: 'setting',
    prop: 'entrustStatus',
    width: '80',
    setting: [
      // {
      //   name: '撤单',
      //   btnType: 'status',
      //   color: '#386aff',
      //   typeReal: 'entrustStatus',
      //   showValue: ORDER_CANCEL
      // },
      {
        name: '重新下单',
        btnType: 'reorder',
        round: true,
        textColor: '#fff',
        bgColor: '#ffb400',
        typeReal: 'entrustStatus', // 显示控制的字段
        showValue: [9, '']
      }
      // {
      //   name: '改单',
      //   btnType: 'update',
      //   color: '#ffb400',
      //   typeReal: 'entrustStatus',
      //   showValue: ORDER_UPDATE
      // }
    ]
  }
])
export const intHeaderList: any = Object.freeze([
  { label: '方向', prop: 'entrustBs', type: 'entrustBs', width: '50' },
  { label: '客户账号', prop: 'fundAccount', type: '', width: '' },
  { label: '客户', prop: 'nameCn', type: '', width: '' },
  {
    label: '股票名称/代码',
    prop: 'enName',
    type: 'twoLinesNewsLines',
    width: '220px',
    propList: [
      { prop: 'stockName', icon: 'exchangeType', class: 'lines-stock-name' },
      { prop: 'stockCode', class: 'lines-stock-code' },
      { prop: 'exchangeType', class: 'lines-stock-type' }
    ]
  },
  { label: '委托价格', prop: 'entrustPrice', type: 'price', width: '100' },
  {
    label: '委托数量',
    prop: 'entrustAmount',
    type: 'permillage',
    width: ''
  },
  { label: '成交价格', prop: 'businessPriceU', type: '', width: '' },
  {
    label: '成交数量',
    prop: 'businessAmount',
    type: 'permillage',
    width: ''
  },
  {
    label: '交易状态',
    prop: 'entrustStatus',
    type: 'status',
    width: ''
  },
  {
    label: '订单类型',
    prop: 'entrustProp',
    type: 'entrustProp',
    width: ''
  },
  {
    label: '订单号',
    prop: 'externalOrderNo',
    type: '',
    width: ''
  },
  {
    label: '下单时间',
    prop: 'createTime',
    type: 'date',
    width: ''
  }
])
