
import { lpoaCancelOrder } from '@/graphql'
import { useMutation } from '@vue/apollo-composable'
import { ElMessage } from 'element-plus'
import { computed, defineComponent, reactive, toRefs, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { tHeaderList } from './data'
import { numFormat } from '@/utils/num_format'
import * as crypoto from '@/utils/crypto'
import useOrderInfo from './hooks/useOrderInfo'
import { ORDER_CANCEL, ORDER_UPDATE, STATES, ENTRUSTPROPMAP } from '@/constant'
import { EntrustStatus, EntrustBs } from '@/constant/enum'
import { setDisabled } from '@/utils/order'

export default defineComponent({
  name: 'checkDetail',
  setup() {
    const route = useRoute()
    const router = useRouter()
    const query = computed(() => route.query)
    const state = reactive({
      tHeaderList,
      dialogVisibleFlag: false, // 查看明细
      dataForm: {} as any,
      confirmLoadinSelectg: false,
      activeIndex: 3,
      options: [
        { name: '改单', value: 0 },
        { name: '撤单', value: 1 }
      ],
      confirmLoading: false
    })
    const params = {
      filter: {
        directiveid: Number(query.value.orderid)
      }
    }
    // 查询订单详情
    const { orderInfoList, tableData, refetchOrderInfo } = useOrderInfo(
      Number(query.value.orderid)
    )
    // 多选
    const orderQty = computed(
      () =>
        tableData.value?.filter(
          (item: { selectStatus: boolean }) => item.selectStatus
        ).length
    )

    const showAction = computed(() =>
      tableData.value?.some(
        (item: { entrustStatus: EntrustStatus }) =>
          ORDER_CANCEL.includes(item.entrustStatus) ||
          ORDER_UPDATE.includes(item.entrustStatus)
      )
    )
    const changeIndex = (index: number) => {
      state.activeIndex = index
      setDisabled(tableData, state.tHeaderList, state.activeIndex)
    }
    // 是否有重新下单
    const isFailed = computed(() =>
      tableData.value?.some(
        (item: { entrustStatus: string }) =>
          !item.entrustStatus ||
          item.entrustStatus.toString() === EntrustStatus.ENTRUSTSTATYS_9
      )
    )
    // 是否有操作列
    const isReOrder = (tHeaderList: any) => {
      const isFailed = tableData.value?.some(
        (item: { entrustStatus: string }) =>
          !item.entrustStatus ||
          item.entrustStatus.toString() === EntrustStatus.ENTRUSTSTATYS_9
      )
      return isFailed
        ? tHeaderList
        : tHeaderList.filter(
            (item: { type: string }) => item.type !== 'setting'
          )
    }
    watch(
      () => [showAction.value, state.activeIndex, isFailed.value],
      () => {
        // 初始进入不选中
        const tHeadList = tHeaderList.filter(
          (item: { type: string }) => item.type !== 'selection'
        )
        if (state.activeIndex === 3) {
          state.tHeaderList = isReOrder(tHeadList)
        } else {
          // 有改单撤单
          if (showAction.value) {
            state.tHeaderList = isReOrder(tHeaderList)
          } else {
            const tHeadList = state.tHeaderList.filter(
              (item: { type: string }) => item.type !== 'selection'
            )
            state.tHeaderList = isReOrder(tHeadList)
          }
        }
      },
      {
        immediate: true
      }
    )
    const cancelSelect = () => {
      state.activeIndex = 3
    }
    const confirmSelect = () => {
      if (!orderQty.value) return
      const list = tableData.value.filter(
        (item: { selectStatus: boolean }) => item.selectStatus
      )
      if (state.activeIndex === 0) {
        // 改单
        const updateList = list.map((item: { stockName: string }) => {
          return {
            ...item,
            stockNameGb: item.stockName
          }
        })
        const type = {
          entrustProp: orderInfoList.value.entrustProp,
          entrustBs: orderInfoList.value.entrustBs,
          entrustPrice: orderInfoList.value.entrustPrice,
          extendedTrading: orderInfoList.value.extendedTrading
        }
        console.log(updateList, '改单', type)
        router.push({
          name: 'BulkTrading',
          query: {
            bidAskList: crypoto.base64Encrypt(JSON.stringify(updateList)),
            type: JSON.stringify(type),
            orderid: query.value.orderid
          }
        })
      } else {
        state.dialogVisibleFlag = true
        state.dataForm = list
      }
    }
    const changeSelect = (item: { grey: boolean, selectStatus: boolean }) => {
      console.log(item, 'changeSelect')
      // if (state.activeIndex === 3) return
    }
    const checkAll = () => {
      // if (state.activeIndex === 3) return
    }
    const change = (data: any) => {
      switch (data.btnType) {
        case 'status':
          state.dialogVisibleFlag = true
          state.dataForm = data.data
          break
        case 'reorder':
          const bidAskList: any = [
            {
              entrustAmount: data.data.entrustAmount,
              entrustPrice: data.data.entrustPrice,
              fundAccount: data.data.fundAccount,
              entrustProp: data.data.entrustProp,
              exchangeType: data.data.exchangeType,
              stockCode: data.data.stockCode,
              stockNameGb: data.data.stockName
            }
          ]
          console.log(bidAskList, '重新下单')
          router.push({
            name: 'BulkTrading',
            params: {
              bidAskList: JSON.stringify(bidAskList)
            }
          })
          break
        default:
          break
      }
    }
    // 撤单
    const { mutate: cancelOrder } = useMutation(lpoaCancelOrder)
    // 多选撤单确认
    const confirm = () => {
      state.confirmLoading = true
      console.log(state.dataForm, 'dataForm')
      const orderinfo = state.dataForm.map(
        (item: { fundAccount: string, externalOrderNo: string }) => {
          return {
            fundAccount: item.fundAccount,
            orderTxnReference: item.externalOrderNo
          }
        }
      )
      cancelOrder({
        params: {
          orderid: Number(query.value.orderid),
          orderinfo
        }
      }).then((res: any) => {
        if (res.data.lpoaCancelOrder.ok) {
          state.confirmLoading = false
          ElMessage({
            message: '撤单已提交',
            type: 'success',
            duration: 1500,
            customClass: 'custom-success'
          })
        }
        // setTimeout(() => {
        refetchOrderInfo(params)
        // }, 500)
      })
      state.dialogVisibleFlag = false
      state.activeIndex = 3
    }

    return {
      EntrustBs,
      ENTRUSTPROPMAP,
      STATES,
      ...toRefs(state),
      orderQty,
      tableData,
      change,
      confirm,
      query,
      numFormat,
      changeSelect,
      checkAll,
      changeIndex,
      cancelSelect,
      confirmSelect,
      orderInfoList,
      showAction
    }
  }
})
