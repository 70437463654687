import { useQuery } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { ref } from 'vue'
import Decimal from 'decimal.js'

export default function useOrderInfo (directiveid: number) {
  const orderInfoList = ref()
  const accountList = ref()
  const tableData = ref()
  const { onResult, refetch } = useQuery(
    gql.lpoaOrderInfo,
    {
      filter: {
        directiveid
      }
    },
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    }
  )

  onResult(res => {
    const { data, loading } = res
    if (!loading) {
      orderInfoList.value = data?.lpoaOrderInfo.data
      accountList.value = data?.lpoaManagersBindInfoList.data
      tableData.value = orderInfoList?.value?.subOrders?.map((item: any) => {
        const name = accountList.value[0]?.bindInfo.filter(
          (ele: any) => ele.account === item.fundAccount
        )
        return {
          ...item,
          extendedTrading: orderInfoList.value.extendedTrading,
          orderId: orderInfoList.value.id || '--',
          createTime: orderInfoList.value.createTime,
          businessPriceU: item.businessPrice
            ? new Decimal(item.businessPrice).toFixed(3, Decimal.ROUND_DOWN)
            : '--',
          exchangeType: orderInfoList.value.exchangeType,
          state: orderInfoList.value.state,
          entrustBs: orderInfoList.value.entrustBs,
          entrustProp: orderInfoList.value.entrustProp,
          stockCode: orderInfoList.value.stockCode,
          stockName: orderInfoList.value.stockName,
          nameCn: name.length
            ? name[0].nameCn ||
              name[0].englishFirstname + name[0].englishLastname
            : '--'
        }
      })
    }
  })
  return {
    orderInfoList,
    tableData,
    refetchOrderInfo: refetch
  }
}
